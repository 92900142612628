import React, { useState } from "react";
import { PurpoffIcon } from "./assets/icons/purpoff.icon";
import "./styles.css";
import { InstagramIcon } from "./assets/icons/instagram.icon";

export const App = () => {
  const [openEmailForm, setOpenEmailForm] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [comingSoonMessage, setComingSoonMessage] = useState(false);

  const onOpenEmailForm = () => {
    setOpenEmailForm(true);
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value.toUpperCase());
  };

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setComingSoonMessage(true);
  };

  return (
    <>
      <div className="page">
        <main className="page__main">
          <section
            className="intro"
            style={comingSoonMessage ? { paddingBottom: "9.2rem" } : {}}
          >
            {openEmailForm ? (
              comingSoonMessage ? (
                <div
                  className={`form-block ${comingSoonMessage ? "visible" : ""}`}
                >
                  <div className="form-block__message">COMING SOON</div>
                </div>
              ) : (
                <div className={`form-block ${openEmailForm ? "visible" : ""}`}>
                  <div className="form-block__ghost"></div>
                  <div className="form-block__text">
                    <p>
                      МИ КОМПАНІЯ PURPOFF, ЩО ЗАЙМАЄТЬСЯ ДОСТАВКОЮ ВЗУТТЯ, Є
                      ЛІДЕРАМИ НА ЦЬОМУ РИНКУ. МИ ПИШАЄМОСЯ ТИМ, ЩО ЗАБЕЗПЕЧУЄМО
                      ПОВНИЙ ЦИКЛ ОБСЛУГОВУВАННЯ – ВІД МОМЕНТУ ЗАМОВЛЕННЯ ДО
                      ЙОГО ОТРИМАННЯ. ПІД НАШИМ КЕРІВНИЦТВОМ МИ ГОТОВІ
                      РЕАЛІЗОВУВАТИ НОВІ ЗАХОПЛЮЮЧІ ПРОЕКТИ ТА СПІВПРАЦІ, ЯКІ
                      ВІДОБРАЖАЮТЬ НАШ ДУХ НЕЗАЛЕЖНОСТІ ТА ЕНТУЗІАЗМУ.
                    </p>
                    <p>
                      МИ ВДЯЧНІ ЗА ПОСТІЙНУ ПІДТРИМКУ ВІД НАШОЇ СПІЛЬНОТИ, ЩО
                      НАДАЄ НАМ СИЛ НА ЦЬОМУ ШЛЯХУ, І З НЕТЕРПІННЯМ ЧЕКАЄМО НА
                      НОВІ МОЖЛИВОСТІ, ЩОБ ЗРОБИТИ ВАШ ДОСВІД З НАМИ ЩЕ КРАЩИМ.
                    </p>
                  </div>
                  <form
                    className="js-send-form form-block__form"
                    onSubmit={onSubmitForm}
                  >
                    <input
                      className="form-block__input"
                      name="email"
                      type="email"
                      value={inputValue}
                      onChange={onInputChange}
                      placeholder="EXAMPLE@GMAIL.COM"
                      required
                    ></input>
                    <button className="form-block__button">
                      ПІДПИСАТИСЬ НА ОНОВЛЕННЯ
                    </button>
                    <a href="https://www.instagram.com/purpoff.market/">
                      <InstagramIcon className="instagram_logo" />
                    </a>
                  </form>
                </div>
              )
            ) : (
              <PurpoffIcon className="intro__logo" onClick={onOpenEmailForm} />
            )}
          </section>
        </main>
      </div>
    </>
  );
};
